import styled from 'styled-components';

const size = {
  xs: '320px',
sm: '768px',
lg: '1200px',
};
const device = {
  xs: `(min-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  lg: `(min-width: ${size.lg})`,
};

export const FormBlock = styled.div`
    max-width: 500px;
    background: #ffffff;
    font-size: 13px;
    box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
    border-radius: 2px;
     @media only screen and ${device.xs}{
            padding: 15px;
    }
    @media only screen and ${device.sm}{
       padding: 15px;
    }
    @media only screen and ${device.lg}{
                 padding: 30px;
    }

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

export const FooterBlock = styled.div`
  border-top: 1px solid #e7e7e7;
  padding-top: 1rem;
`;
export const AppContainer = styled.div`
    display: flex;
    align-items: start;
    justify-content: flex-end;
    flex-wrap: wrap;
    height: 100%;
`;

export const LinkButton = styled.span`
  background: none;
  border: none;
  cursor: pointer;
`;
export const TextContent = styled.div`
     h1 {
        margin-bottom: 20px;
     }
     width: 60%;
     padding: 50px;
     cursor: default;
     @media (max-width: 1100px) {
      width: 100%;
    }
`;
export const FormContent = styled.div<{ hasCustomer: boolean }>`
    width: ${(props: any) => props.hasCustomer ? '40%' : '100%'};
    height: 100%;
    position: fixed;
    left: 0;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    padding: 50px;
    @media (max-width: 1100px) {
      width: 100%;
      position: static;
    }
    @media only screen and ${device.xs}{
      padding: 17px 0;
    }
    .nav-pills .nav-link {
        white-space: pre;
    }
`;

export const InitiativeBlock = styled.div`
  .title {
    font-size: 14px;
    margin-bottom: 10px;
    letter-spacing: 0px;
    padding: 0 5px 5px;
    border-bottom: 3px solid #fff;
    cursor: pointer;
    &:hover {
      border-color: #ccc;
    }
  }
  
  button {
    font-size: 12px;

  }
  
`;
