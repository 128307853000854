import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Initiative } from '../../services/api.service';
import { Button, Col, Collapse, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa';

interface InitiativesComponentProps {
  initiatives: Initiative[];
  initiative: Initiative;
  doInitiativeChange: (initiative: Initiative) => void;
}

const Block = styled.div`
  margin: 10px 0;
  .text-xs {
    font-size: 11px;
  }
  h2 {
    font-size: 14px;
  }

  .title {

  }
  .selected-item {
    font-weight: bold;

    .select-flag {
      margin-right: 10px;
    }
  }
`;

const InitiativesComponent = (props: InitiativesComponentProps) => {

  const [isOpen, setIsOpen] = useState(false);

  const { i18n, t } = useTranslation();

  useEffect(() => {

  }, []);

  const getName = (initiative: Initiative) => {
    return !initiative.name[i18n.language] ? initiative.name['en'] : initiative.name[i18n.language];
  };

  const changeInitiative = (initiative: Initiative) => {
    props.doInitiativeChange(initiative);
    setIsOpen(false);
  };

  return <Block>

    <h2>{t('chooseInitiative')}</h2>

    <ListGroup>
      <ListGroupItem>
        <Row>
          <Col md={10}>
            <span className={"title"}>{getName(props.initiative)}</span>
          </Col>
          <Col md={2}>
            <Button className={"p-0 text-xs"} size={'sm'} color="link"  onClick={() => {
              setIsOpen(!isOpen);
            }
            }>{isOpen ? t('close') : t('select')}</Button>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
    <Collapse isOpen={isOpen}>
      <ListGroup>
        {
          props.initiatives.map((val) => {
            const isActive = val.initiativeId === props.initiative.initiativeId;
            return <ListGroupItem key={val.initiativeId}
                                  action
                                  active={isActive}
                                  tag="button"
                                  onClick={() => {
                                    changeInitiative(val);
                                  }
                                  }
            >
              {isActive && <div className={'selected-item'}><span className={'select-flag'}><FaCheck/></span> {getName(val)}</div>}
              {!isActive && getName(val)}
            </ListGroupItem>;
          })
        }
      </ListGroup>
    </Collapse>
  </Block>;
};

export default InitiativesComponent;
