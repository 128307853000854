import { stateToHTML } from 'draft-js-export-html';
import React, { useEffect, useState } from 'react';
import { EditorState } from 'draft-js';
import { convertFromRaw } from 'draft-js';

interface ContentDraftProps {
  text: string;
}

const ContentDraft = (props: ContentDraftProps) => {

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  useEffect(() => {
    const raw = JSON.parse(props.text);
    const content = convertFromRaw(raw);
    setEditorState(EditorState.createWithContent(content));
  }, []);

  const draftToHtml = (state: EditorState) => {
    return stateToHTML(state.getCurrentContent());
  };

  return <div dangerouslySetInnerHTML={{
    __html: draftToHtml(editorState),
  }}/>;
};

export default ContentDraft;
