import { Alert, Initiative } from '../../services/api.service';

export type Action =
  | {
  type: 'SET_FIELD_VALIDATION', payload: {
    fieldName: string,
    isTouched: boolean,
    isValid: boolean,
    message: string,
  }}
  | { type: 'SET_CUSTOM_AMOUNT', payload: { amount: number } }
  | { type: 'SET_INITIATIVE', payload: { initiative: Initiative } }
  | { type: 'SET_IS_CUSTOM_AMOUNT', payload: { isCustomAmount: boolean } }
  | { type: 'SET_AMOUNT', payload: { amount: number } }
  | { type: 'SET_ALERT', payload: { alert: Alert } }
  | { type: 'SET_FORM_FIELD_VALUE', payload: { fieldName: string, value: string } }
  | { type: 'SET_TRANSACTION_TYPE', payload: { transactionTypeId: number } }
  | { type: 'SET_ERROR_MESSAGE', payload: { errorMessage: string } };

export const setTransactionType = (transactionTypeId: number): Action => ({
  type: 'SET_TRANSACTION_TYPE',
  payload: {
    transactionTypeId,
  },
});
export const setAmount = (amount: number): Action => ({
  type: 'SET_AMOUNT',
  payload: {
    amount,
  },
});

export const setFormFieldValue = (fieldName: string, value: string): Action => ({
  type: 'SET_FORM_FIELD_VALUE',
  payload: {
    fieldName,
    value,
  },
});

export const setCustomAmount = (amount: number): Action => ({
  type: 'SET_CUSTOM_AMOUNT',
  payload: {
    amount,
  },
});
export const setAlert = (alert: Alert): Action => ({
  type: 'SET_ALERT',
  payload: {
    alert,
  },
});

export const setIsCustomAmount = (isCustomAmount: boolean = false): Action => ({
  type: 'SET_IS_CUSTOM_AMOUNT',
  payload: {
    isCustomAmount,
  },
});
export const setInitiative = (initiative: Initiative): Action => ({
  type: 'SET_INITIATIVE',
  payload: {
    initiative,
  },
});

export const setFieldValidation = (
  fieldName: string,
  isTouched: boolean = false,
  isValid: boolean = false,
  message: string = '',
): Action => ({
  type: 'SET_FIELD_VALIDATION',
  payload: {
    fieldName,
    isTouched,
    isValid,
    message,
  },
});
