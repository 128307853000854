export const isValidEmail = (email: string) => {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
};
export const getMoneySizes = (str: string | undefined): number[] => {
  if (!str) {
    return [0];
  }
  return str.split(',').map((value: string) => +value);
};

export const getNumeric = (str?: string | null): number => {
  if (!str) {
    return 10000;
  }
  return !isNaN(parseFloat(str)) ? parseFloat(str) * 100 : 10000;
};
