import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Customer, getCustomer } from '../services/api.service';
import 'react-toastify/dist/ReactToastify.css';
import ContentDraft from '../components/content.draft';
import Footer from '../components/footer';
import { AppContainer, FormBlock, FormContent, TextContent } from '../components/styles';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import App from './App/app';
import Unsubscribe from './Unsubscribe';
import LanguageSwitcher from '../components/language-switcher';
import { useTranslation } from 'react-i18next';

function Layout(): any {
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const {t} = useTranslation();

  const location: any = useLocation();
  const params = new URLSearchParams(location.search);
  const clientId: string | null = params.get('client_id');

  useEffect(() => {
    if (!clientId) {
      // dispatch error page;
      return;
    }
    (async () => {
      setLoading(true);

      const currentCustomer: Customer = await getCustomer({
        clientId,
      });
      if (currentCustomer) {
        document.title = `${currentCustomer.attributes.name}`;
        setCustomer(currentCustomer);
        setLoading(false);
      }
    })().catch(() => {
      setLoading(false);
    });
  }, [clientId]);

  return (
      <AppContainer className="App" >
      <FormContent hasCustomer={!!customer}>
        <FormBlock className={'mx-auto'}>
          {loading ? t('loading') : <>
            {!!customer && <div>
              {
                <Switch>
                  <Route path={`/pay`}>
                    <App customer={customer}/>
                  </Route>
                  <Route path={`/unsubscribe/:unsubscribeToken`}>
                    <Unsubscribe customer={customer}/>
                  </Route>
                  <Route path="/">
                    <Redirect to={'/pay'}/>
                  </Route>
                  <Route path="*">
                    <div>{t('notFound')}</div>
                  </Route>
                </Switch>}
            </div>}

            {!customer &&
            <p>{t('notFound')}</p>}
            <Row>
              <Col>
                <Footer customer={customer}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <LanguageSwitcher/>
              </Col>
            </Row>
          </>}
        </FormBlock>
      </FormContent>

      {!!customer && <TextContent>
        <h1>{customer?.attributes?.name}</h1>
        {customer?.attributes?.description && <ContentDraft text={customer.attributes?.description}/>}
      </TextContent>}
    </AppContainer>
  );
}

export default Layout;
