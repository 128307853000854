import React from 'react';
import './assets/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BrowserRouter as Router } from 'react-router-dom';

import { createGlobalStyle } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import Layout from './containers/layout';
import i18n from './services/i18n';
import { I18nextProvider } from 'react-i18next';
import { createRoot } from 'react-dom/client';

const GlobalStyle = createGlobalStyle`
    .alert-dismissible .close {
        padding: 0.63rem 1.25rem;
    }
`;

export default GlobalStyle;

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <GoogleReCaptchaProvider
          reCaptchaKey="6Le-eyEaAAAAAJQgVpNA1c4LwaPlHSMKg7s-lAYy"
          language="hy"
          scriptProps={{
            async: false,
            defer: false,
            appendTo: 'head',
            nonce: undefined,
          }}
        >
          <GlobalStyle/>
          <ToastContainer
            autoClose={5000}
            hideProgressBar={true}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={true}
            position={'top-left'}
          />
          <Router>
            <Layout/>
          </Router>
        </GoogleReCaptchaProvider>
      </I18nextProvider>
    </React.StrictMode>,
  );
} else {
  console.error('Root container not found');
}
