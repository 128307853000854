import { Reducer } from 'react';
import { TRANSACTION_TYPES } from '../../helpers/constants.helper';
import { Action } from './app.actions';
import { Initiative } from '../../services/api.service';

export interface Validation {
  isValid: boolean;
  isTouched: boolean;
  message: string;
}

interface State {
  initiative?: Initiative;
  transactionTypeId: number;
  amount: number;
  isLoading: boolean;
  isCustomAmount: boolean;
  form: {
    firstName: string;
    lastName: string;
    email: string;
    notes: string;
    termsAndConditions: string;
  };
  validation: {
    firstName: Validation,
    lastName: Validation,
    email: Validation,
    termsAndConditions: Validation,
    amount: Validation,
  };
  alert: {
    isTransactionSucceed: boolean,
    message: string,
    isTouched: boolean,
  };
}

export const initialState: State = {
  amount: 0,
  isLoading: false,
  isCustomAmount: false,
  transactionTypeId: TRANSACTION_TYPES[0].value,
  form: {
    firstName: '',
    lastName: '',
    email: '',
    notes: '',
    termsAndConditions: '',
  },
  validation: {
    firstName: {
      isTouched: false,
      isValid: false,
      message: '',
    },
    lastName: {
      isTouched: false,
      isValid: false,
      message: '',
    },
    email: {
      isTouched: false,
      isValid: false,
      message: '',
    },
    amount: {
      isTouched: true,
      isValid: true,
      message: '',
    },
    termsAndConditions: {
      isTouched: false,
      isValid: false,
      message: '',
    },
  },
  alert: {
    isTransactionSucceed: false,
    message: '',
    isTouched: false,
  },
  initiative: undefined,
};

export const reducer: Reducer<State, Action> = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_TRANSACTION_TYPE':
      return {
        ...state,
        transactionTypeId: action.payload.transactionTypeId,
      };
    case 'SET_AMOUNT':
      return {
        ...state,
        amount: action.payload.amount,
        isCustomAmount: false,
        validation: {
          ...state.validation,
          amount: {
            isValid: true,
            isTouched: true,
            message: '',
          },
        },
      };
    case 'SET_CUSTOM_AMOUNT':
      return {
        ...state,
        isCustomAmount: true,
        amount: action.payload.amount,
      };
    case 'SET_ALERT':
      return {
        ...state,
        alert: action.payload.alert,
      };
    case 'SET_IS_CUSTOM_AMOUNT':
      return {
        ...state,
        isCustomAmount: action.payload.isCustomAmount,
        amount: 100000,
        validation: {
          ...state.validation,
          amount: {
            isTouched: true,
            isValid: true,
            message: '',
          },
        },
      };
    case 'SET_FORM_FIELD_VALUE':
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.fieldName]: action.payload.value,
        },
      };
    case 'SET_INITIATIVE':
      return {
        ...state,
        initiative: action.payload.initiative,
      };
    case 'SET_FIELD_VALIDATION':
      return {
        ...state,
        validation: {
          ...state.validation,
          [action.payload.fieldName]: {
            isTouched: action.payload.isTouched,
            isValid: action.payload.isValid,
            message: action.payload.message,
          },
        },
      };
    default:
      throw new Error('Unknown action');
  }
};
