import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { checkUnsubscribeToken, Customer, unsubscribe } from '../services/api.service';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

interface UnsubscribeProps {
  customer: Customer;
}

const Block = styled.div``;

const ConfirmBlock = styled.div`
  margin: 50px;
  cursor: default;
  text-align: center;
`;
const ConfirmText = styled.div`
  font-size: 18px;
  margin-bottom: 30px;
`;

const ErrorMessage = styled.div`
  margin-bottom: 10px;
`;

const Unsubscribe = (props: UnsubscribeProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const {t} = useTranslation();
  const [unsubscribed, setUnsubscribed] = useState({
    isTouched: false,
    isSucceed: false,
  });
  const { unsubscribeToken } = useParams<{ unsubscribeToken: string }>();

  const doUnsubscribe = () => {
    unsubscribe({
      unsubscribeToken,
      clientId: props.customer.clientId,
    }).then(() => {
      setUnsubscribed({
        isSucceed: true,
        isTouched: true,
      });
      toast.success(t('unsubscribe.succeed') as string);
    })
      .catch(() => {
        toast.error(t('unsubscribe.failed') as string);
      });
  };

  useEffect(() => {
    checkUnsubscribeToken({
      unsubscribeToken,
      clientId: props.customer.clientId,
    }).catch(() => {
      setErrorMessage(t('invalidRequest'));
    });
  }, []);

  const isUnsubscribed = (unsubscribed.isTouched && unsubscribed.isSucceed);

  return <Block>
    {errorMessage !== '' && <ErrorMessage>{errorMessage}</ErrorMessage>}
    {errorMessage === '' && <ConfirmBlock>
      <ConfirmText>{isUnsubscribed ? t('unsubscribe.succeed')
        : t('unsubscribe.confirm')}</ConfirmText>
      {!isUnsubscribed &&
      <Button onClick={() => doUnsubscribe()} color={'primary'}>{t('submit')}</Button>}
    </ConfirmBlock>}
  </Block>;
};

export default Unsubscribe;
