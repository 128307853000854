import React, { useCallback, useEffect, useReducer, useState } from 'react';
import numeral from 'numeral';
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledAlert,
} from 'reactstrap';
import { TRANSACTION_TYPES, TransactionType, VALIDATION_MESSAGES } from '../../helpers/constants.helper';
import { initialState, reducer, Validation } from './app.reducer';
import {
  setAlert,
  setAmount,
  setCustomAmount,
  setFieldValidation,
  setFormFieldValue, setInitiative,
  setIsCustomAmount,
  setTransactionType,
} from './app.actions';
import { getMoneySizes, getNumeric, isValidEmail } from '../../helpers/utils.helper';
import { authorizeTransaction, Customer, getAlert, Initiative } from '../../services/api.service';
import { toast } from 'react-toastify';
import ContentDraft from '../../components/content.draft';
import { LinkButton } from '../../components/styles';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InitiativesComponent from './initiatives.component';

interface AppProps {
  customer: Customer | null;
}

function App(props: AppProps): any {

  const [state, dispatch] = useReducer(reducer, initialState);
  const [modal, setModal] = useState(false);
  const { t } = useTranslation();

  const location: any = useLocation();
  const params = new URLSearchParams(location.search);
  const sessionId: string | null = params.get('session_id');
  const myNotes: string | null = params.get('notes');
  const amount: number = getNumeric(params.get('amount'));

  const isOneTimePayment: boolean = props.customer?.attributes?.isOneTimePayment?.toString() === 'true';
  const isMoneySizesEnabled: boolean = props.customer?.attributes?.isMoneySizesEnabled?.toString() === 'true';
  const isInitiativesEnabled: boolean = props.customer?.attributes?.isInitiativesEnabled?.toString() === 'true';
  const initiatives: Initiative[] = props.customer?.attributes?.initiatives ? JSON.parse(props.customer?.attributes?.initiatives) : [];

  const toggleModal = (e: any) => {
    e.stopPropagation();
    setModal(!modal);
  };

  useEffect(() => {
    const moneySizes: number[] = getMoneySizes(props.customer?.attributes.moneySizes);

    if (sessionId) {
      getAlert(sessionId).then((session: any) => {
        if (session) {
          const { isTransactionSucceed } = session;
          const message = isTransactionSucceed ? t('transaction.succeed') : t('transaction.failed');
          toast(message, {
            type: isTransactionSucceed ? 'success' : 'error',
          });
          dispatch(setAlert({
            isTransactionSucceed,
            message,
            isTouched: true,
          }));
        }
      });
    }

    if (isMoneySizesEnabled) {
      dispatch(setAmount(moneySizes[0]));
    } else {
      dispatch(setAmount(amount));
    }

    if (isInitiativesEnabled) {
      dispatch(setInitiative(initiatives[0]));
    }

    if (myNotes) {
      dispatch(setFormFieldValue('notes', myNotes));
    }
  }, []);

  const validateField = (name: string, value: any, checked?: boolean) => {
    let isValid = false;
    let message = '';

    switch (name) {
      case 'firstName':
      case 'lastName':
        isValid = value.trim() !== '';
        message = isValid ? '' : VALIDATION_MESSAGES.REQUIRED;
        break;
      case 'email':
        if (value.trim() !== '') {
          isValid = isValidEmail(value);
          message = isValid ? '' : VALIDATION_MESSAGES.INVALID_EMAIL;
        } else {
          isValid = false;
          message = VALIDATION_MESSAGES.REQUIRED;
        }
        break;
      case 'termsAndConditions':
        if (typeof checked !== 'undefined') {
          isValid = checked;
        }
        message = isValid ? '' : VALIDATION_MESSAGES.MUST_ACCEPT_TERMS_AND_CONDITIONS;
        break;
      case 'amount':
        if (value) {
          isValid = +value >= 100;
          message = isValid ? '' : VALIDATION_MESSAGES.MUST_BE_MIN_VALUE;
        } else {
          isValid = false;
          message = VALIDATION_MESSAGES.REQUIRED;
        }
        break;
      default:
        isValid = true;
        break;
    }

    dispatch(setFieldValidation(name, true, isValid, message));
  };

  const doAmountUpdate = useCallback((val: number) => {
    dispatch(setAmount(val));
  }, []);

  const doCustomAmountUpdate = (val: number) => {
    dispatch(setCustomAmount(val * 100));
    validateField('amount', val);
  };

  const doTransactionTypeUpdate = useCallback((val: number) => {
    dispatch(setTransactionType(val));
  }, []);

  const doIsCustomAmountUpdate = useCallback(() => {
    dispatch(setIsCustomAmount(true));
  }, []);

  const doFormFieldUpdate = ({ target: { value, name, checked } }: any) => {
    dispatch(setFormFieldValue(name, name === 'termsAndConditions' ? checked : value));
    if (name !== 'notes') {
      validateField(name, value, checked);
    }
  };
  const doInitiativeChange = (initiative: Initiative) => {
    dispatch(setInitiative(initiative));
  };

  const isFormValid: boolean = Object.values(state.validation).every((field: Validation) => field.isValid);

  const doFormSubmit = async (e: any) => {
    e.preventDefault();
    if (!isFormValid) {
      return;
    }
    if (!props.customer) {
      return;
    }

    const { clientId } = props.customer;

    const { email, firstName, lastName, notes } = state.form;
    const nextUrl: any = `${window.location.origin}${window.location.pathname}?client_id=${clientId}`;
    try {
      const { formUrl }: any = await authorizeTransaction({
        amount: state.amount, clientId: props.customer.clientId, email, firstName, lastName,
        transactionTypeId: state.transactionTypeId,
        nextUrl,
        notes: `[${state.initiative?.initiativeId}] ${notes}`
      });
      window.location.href = formUrl;
    } catch (e) {
      toast.error('Unable to authorize the payment order.');
    }
  };

  const isFieldInvalid = (field: Validation) => {
    return !field.isValid && field.isTouched;
  };

  return (
    <div>
      <Form onSubmit={doFormSubmit}>
        <Col>
          {state.alert.isTouched && <UncontrolledAlert
            color={state.alert.isTransactionSucceed ? 'success' : 'danger'}>
            {state.alert.message}
          </UncontrolledAlert>}

          {!isOneTimePayment && <Row>
            <Col>
              <FormGroup>
                <Nav justified={true} pills={true}>
                  {TRANSACTION_TYPES.map(({ label, value }: TransactionType) => {
                    return (<NavItem
                      color="primary"
                      key={value}
                    ><NavLink href={'#'} onClick={() => doTransactionTypeUpdate(value)}
                              active={state.transactionTypeId === value}>{t(label)}</NavLink></NavItem>);
                  })}
                </Nav>
              </FormGroup>
            </Col>
          </Row>}

          {isMoneySizesEnabled && <Row>
            <Col>
              <FormGroup>
                <Nav justified={true} pills={true}>
                  {props.customer?.attributes?.moneySizes.split(',').map((value: string) => {
                    const availableAmount: number = +value;
                    return (<NavItem
                      color="primary"
                      key={availableAmount}
                    ><NavLink href={'#'} onClick={() => doAmountUpdate(availableAmount)}
                              active={state.amount === availableAmount}>
                      {numeral(availableAmount / 100).format('0,0')} {t('AMD')}</NavLink></NavItem>);
                  })}
                  <NavItem
                    color="primary"
                    key={-1}
                  ><NavLink href={'#'} onClick={() => doIsCustomAmountUpdate()}
                            active={state.isCustomAmount}>{t('other')}</NavLink></NavItem>
                </Nav>
              </FormGroup>
            </Col>
          </Row>}
          {isInitiativesEnabled && state.initiative && <InitiativesComponent doInitiativeChange={doInitiativeChange} initiatives={initiatives} initiative={state.initiative}/>}
          <Row>
            {(state.isCustomAmount || !isMoneySizesEnabled) && <Col lg={12}>
              <FormGroup>
                <Label for="amount">{t('amount')} (AMD)</Label>
                <Input min={100} step={100} bsSize="sm" value={state.amount / 100}
                       onChange={(e: any) => {
                         doCustomAmountUpdate(e.target.value);
                       }}
                       invalid={isFieldInvalid(state.validation.amount)}
                       type="number" name="amount" id="amount"/>
                {isFieldInvalid(state.validation.amount) && <FormFeedback>{state.validation.amount.message}</FormFeedback>}
              </FormGroup>
            </Col>}

            <Col lg={6}>
              <FormGroup>
                <Label for="firstName">{t('firstName')}</Label>
                <Input invalid={isFieldInvalid(state.validation.firstName)} bsSize="sm"
                       onChange={doFormFieldUpdate}
                       value={state.form.firstName}
                       type="text"
                       name="firstName"
                       id="firstName"/>
                {isFieldInvalid(state.validation.firstName) &&
                  <FormFeedback>{state.validation.firstName.message}</FormFeedback>}
              </FormGroup>
            </Col>
            <Col lg={6}>
              <FormGroup>
                <Label for="lastName">{t('lastName')}</Label>
                <Input bsSize="sm" onChange={doFormFieldUpdate}
                       invalid={isFieldInvalid(state.validation.lastName)}
                       value={state.form.lastName}
                       type="text"
                       name="lastName"
                       id="lastName"/>

                {isFieldInvalid(state.validation.lastName) &&
                  <FormFeedback>{state.validation.lastName.message}</FormFeedback>}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormGroup>
                <Label for="email">{t('email')}</Label>
                <Input bsSize="sm" onChange={doFormFieldUpdate}
                       invalid={isFieldInvalid(state.validation.email)}
                       value={state.form.email}
                       type="text"
                       name="email"
                       id="email"/>
                {isFieldInvalid(state.validation.email) && <FormFeedback>{state.validation.email.message}</FormFeedback>}
              </FormGroup>
            </Col>
            <Col lg={12}>
              <FormGroup>
                <Label for="notes">{t('notes')}</Label>
                <Input bsSize="sm" onChange={doFormFieldUpdate}
                       value={state.form.notes}
                       type="textarea"
                       rows={4}
                       name="notes"
                       id="notes"/>
              </FormGroup>
            </Col>
            <Col lg={12}>
              <FormGroup check={true}>
                <Label check={true}>
                  <Input invalid={isFieldInvalid(state.validation.termsAndConditions)} value={state.form.termsAndConditions}
                         type="checkbox" onChange={doFormFieldUpdate} name="termsAndConditions"/>{' '}
                  {t('agreeTermsAndConditions')}
                </Label> <LinkButton className={'text-primary'} onClick={toggleModal}>{t('read')}</LinkButton>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormGroup>
                <Button disabled={!isFormValid} size={'sm'} className={'mt-3'} color={'primary'}
                        type={'submit'}>{t('submit')}</Button>
              </FormGroup>
            </Col>
          </Row>

        </Col>
      </Form>

      <Modal size={'lg'} isOpen={modal} toggle={toggleModal}>
        <ModalBody>
          {props.customer?.attributes?.privacyPolicy && <ContentDraft text={props.customer.attributes?.privacyPolicy}/>}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" size={'sm'} onClick={toggleModal}>{t('close')}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default App;
