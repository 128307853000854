import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const SwitcherBlock = styled.div`
  border-top: 1px solid #e7e7e7;
  padding-top: 1rem;
  text-align: center;
`;

const Switcher = styled.button<{ isActive: boolean }>`
      color: ${(props: any) => props.isActive ? '#007bff' : '#212529'};
      background: transparent;
      border: none;
`;

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [activeLang, setActiveLang] = useState('');

  useEffect(() => {
    setActiveLang(i18n.language);
  }, []);

  const changeLanguage = (lang: string) => {
    (async () => {
      await i18n.changeLanguage(lang, () => {
        setActiveLang(lang);
      });
    })();
  };

  const languageMap: any = {
    hy: 'Հայերեն',
    en: 'English',
    ru: 'Русский',
    fr: 'Français',
  };

  return (<SwitcherBlock>
    {Object.keys(languageMap).map((lang: string) => {
      return <Switcher key={lang} isActive={activeLang === lang} onClick={() => {
        changeLanguage(lang);
      }}>{languageMap[lang]}</Switcher>;
    })}
  </SwitcherBlock>);
};

export default LanguageSwitcher;
