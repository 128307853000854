import PaymentLogos from './payment-logos';
import Copyright from './copyright';
import React from 'react';
import { Customer } from '../services/api.service';
import { FooterBlock } from './styles';

interface FooterProps {
  customer: Customer | null;
}

const Footer = ({ customer }: FooterProps) => {
  return <FooterBlock>
    <PaymentLogos/>
    <Copyright customer={customer}/>
  </FooterBlock>;
};

export default Footer;
